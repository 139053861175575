const URL = {
    // SERVER_API_URL: 'http://localhost:4000',
    // SERVER_API_URL: 'https://appupgrade-backend.herokuapp.com',
    SERVER_API_URL: 'https://appupgrade.dev',
};

const Colors = {
    PRIMARY_COLOR: 'Some other action',
};

const Constants = {
    URL,
    Colors,
}

export default Constants;